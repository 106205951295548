import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../../components/Layout'
import Footer from '../../components/footer'
import Header from '../../components/header'
import facebooklogo from '../../assets/images/icon/facebook.svg'
import linkedinlogo from '../../assets/images/icon/linkedin.svg'
import ddlogo from '../../assets/images/icon/dd.svg'
import knsiconwhite from '../../assets/images/icon/kns-icon-white.svg'
import ImageGallery from 'react-image-gallery'
import $ from 'jquery'
import LocalizedLink from '../../LocalizedLink'

class ProductDigitalSignage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProject: null,
      images: [],
    }
  }
  setSelectedProject(name, array) {
    this.setState({ selectedProject: name, images: array })
  }

  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="dt-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>
          <section
            className="solution-detail digital-directory"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1534802046520-4f27db7f3ae5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3501&q=80')",
            }}
          >
            <div className="dark-filter" />
            <section className="page">
              <div className="container">
                <div className="col-12">
                  <figure className="kns-grey-icon">
                    <img src={knsiconwhite} />
                  </figure>
                  <div className="page-prp">
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <h1>FACILITY MANAGEMENT PLATFORM</h1>
                        <p>ALL-IN-ONE SOLUTION FOR FACILITY MANAGEMENT</p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <h1>FACILITY MANAGEMENT PLATFORM</h1>
                        <p>ALL-IN-ONE SOLUTION FOR FACILITY MANAGEMENT</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/*<div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
          </section>
          <div className="container">
            <article className="dt-content">
              {this.props.pageContext.locale == 'tr' && (
                <>
                  <p>
                    Shopping malls are getting larger every day; as a result,
                    managing them becomes a challenging task. It is a long and
                    troublesome process for the mall management who has to carry
                    out separate communication processes inside the mall and
                    with the outsource companies.
                  </p>
                  <p>
                    Facility Management Platform gathers all this process
                    management on the same platform and provides a smooth
                    communication between the parties.{' '}
                  </p>
                  <p>How do we help the malls?</p>
                </>
              )}
              {this.props.pageContext.locale == 'en' && (
                <>
                  <p>
                    Shopping malls are getting larger every day; as a result,
                    managing them becomes a challenging task. It is a long and
                    troublesome process for the mall management who has to carry
                    out separate communication processes inside the mall and
                    with the outsource companies.
                  </p>
                  <p>
                    Facility Management Platform gathers all this process
                    management on the same platform and provides a smooth
                    communication between the parties.{' '}
                  </p>
                  <p>How do we help the malls?</p>
                </>
              )}

              <div className="row content-prp">
                {/*<div className="col-6 text-right"><img src={ddlogo}></img></div>*/}
                <div className="col-12 text-left">
                  <ul>
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <li>
                          Facility Management Platform organizes the work flow
                          between the tenant, the mall and the third party
                          partner companies; making the process significantly
                          easy to manage.
                        </li>
                        <li>
                          Technical, architectural, visual requests or the
                          campaigns that tenants want to publish on the digital
                          channels of the mall are delivered to the mall
                          management, the management responds to those requests
                          in line with their strategical plan. This provides a
                          fast and problem free work flow.{' '}
                        </li>
                        <li>
                          Mall managers, tenants or outsource companies are able
                          to publish their announcements and news through this
                          platform
                        </li>
                        <li>
                          The platform keeps a record of all the interactions
                          between the tenant, the mall management and the third
                          party companies. That way, all the communication
                          during the work flow is archived.{' '}
                        </li>
                        <li>
                          The information on the platform is constantly updated.
                          For instance, it is possible to reach the new tenant
                          through the system swiftly for a store that has shut
                          down.{' '}
                        </li>
                      </>
                    )}

                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <li>
                          Facility Management Platform organizes the work flow
                          between the tenant, the mall and the third party
                          partner companies; making the process significantly
                          easy to manage.
                        </li>
                        <li>
                          Technical, architectural, visual requests or the
                          campaigns that tenants want to publish on the digital
                          channels of the mall are delivered to the mall
                          management, the management responds to those requests
                          in line with their strategical plan. This provides a
                          fast and problem free work flow.{' '}
                        </li>
                        <li>
                          Mall managers, tenants or outsource companies are able
                          to publish their announcements and news through this
                          platform
                        </li>
                        <li>
                          The platform keeps a record of all the interactions
                          between the tenant, the mall management and the third
                          party companies. That way, all the communication
                          during the work flow is archived.{' '}
                        </li>
                        <li>
                          The information on the platform is constantly updated.
                          For instance, it is possible to reach the new tenant
                          through the system swiftly for a store that has shut
                          down.{' '}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>

              {/* <a className="fancybox" href="https://source.unsplash.com/juHayWuaaoQ/1500x1000" data-fancybox="images" data-caption="Backpackers following a dirt trail">
                                  <img src="https://source.unsplash.com/juHayWuaaoQ/240x160" />
                                </a>
                              
                                <a className="fancybox" href="https://source.unsplash.com/eWFdaPRFjwE/1500x1000" data-fancybox="images" data-caption="Mallorca, Llubí, Spain">
                                  <img src="https://source.unsplash.com/eWFdaPRFjwE/240x160" />
                                </a>
                                
                                <a className="fancybox" href="https://source.unsplash.com/c1JxO-uAZd0/1500x1000" data-fancybox="images" data-caption="Danish summer">
                                  <img src="https://source.unsplash.com/c1JxO-uAZd0/240x160" />
                                </a>
                              
                                <a className="fancybox" href="https://source.unsplash.com/eXHeq48Z-Q4/1500x1000" data-fancybox="images" data-caption="Sunrise above a sandy beach">
                                  <img src="https://source.unsplash.com/eXHeq48Z-Q4/240x160" />
                                </a>
                              
                                <a className="fancybox" href="https://source.unsplash.com/RFgO9B_OR4g/1500x1000" data-fancybox="images" data-caption="Woman on a slope by the shore">
                                  <img src="https://source.unsplash.com/RFgO9B_OR4g/240x160" />
                                </a>
                              
                                <a className="fancybox" href="https://source.unsplash.com/7bwQXzbF6KE/1500x1000" data-fancybox="images" data-caption="Mountain hiking sunset">
                                  <img src="https://source.unsplash.com/7bwQXzbF6KE/240x160" />
                                </a>
                              
                                <a className="fancybox" href="https://source.unsplash.com/NhU0nUR7920/1500x1000" data-fancybox="images" data-caption="Sunset Picnic">
                                  <img src="https://source.unsplash.com/NhU0nUR7920/240x160" />
                                </a>
                                
                                <a className="fancybox" href="https://source.unsplash.com/B2LYYV9-y0s/1500x1000" data-fancybox="images" data-caption="On them Indiana Nights">
                                  <img src="https://source.unsplash.com/B2LYYV9-y0s/240x160" />
                                </a> */}
            </article>
          </div>
          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <Footer />
        </body>
      </Layout>
    )
  }
}
ProductDigitalSignage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductDigitalSignage
